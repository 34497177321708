﻿enum BotPickStyle {
  Best = 0,
  Good = 1,
  Average = 2,
  Bad = 3,
  Worst = 4,
  Random = 5
}

export default BotPickStyle
